import { ChangeDetectorRef, Component } from '@angular/core';
import { XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from './enums/config-manager-properties.enum';
import { EMPTY, Observable, catchError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoggedInUserService } from './services/logged-in-user.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  build: string;
  feedbackAddress: SafeUrl;
  
  constructor(
    private configManagerService: ConfigManagerService,
    private authService: XpoLtlAuthenticationService,
    private loggedInUserService:LoggedInUserService,
    private domSanitizer: DomSanitizer, 
    private cdr: ChangeDetectorRef
  ) {
    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    this.authService.initAuthSetup$(region).subscribe();
    this.build = this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
    this.getLoggedInUSer();
    this.setFeedbackAddress();    
  }
  
  getLoggedInUSer() {
    this.loggedInUserService
                .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
  }
  setFeedbackAddress() {
    const email = this.configManagerService.getSetting(ConfigManagerProperties.feedbackToAddress);
    console.log(email);
    
    if (email) {
      this.feedbackAddress = this.domSanitizer.bypassSecurityTrustUrl(`mailto:${email}`);
    }
  }
}

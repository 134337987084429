import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { XpoShellModule } from '@xpo-ltl/ngx-ltl-core/shell';
import { XpoFooterModule } from '@xpo-ltl/ngx-ltl-core/footer';
import { XpoLtlAuthUiModule } from '@xpo-ltl/ngx-auth-ui';
import { XPO_AUTH_CONFIG, XpoAuthConfig } from '@xpo/ngx-auth';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import { CONFIG_MANAGER_SERVICE_CONFIGURATION, ConfigManagerModule } from '@xpo-ltl/config-manager';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataApiModule } from '@xpo-ltl/data-api';
import { MainComponent } from './main/main.component';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { AcorFormComponent } from './acor-form/acor-form.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { SdkShipmentModule, ShipmentApiService } from '@xpo-ltl-2.0/sdk-shipment';
import { BillCorrectionApiService } from '@xpo-ltl-2.0/sdk-billcorrection';
import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { DockOperationsApiService } from '@xpo-ltl/sdk-dockoperations';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

const materialModules = [
  MatButtonModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  MatSelectModule,
  MatCardModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
];


@NgModule({
  declarations: [AppComponent, MainComponent, AcorFormComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    ConfigManagerModule,
    DataApiModule,
    BrowserModule,
    BrowserAnimationsModule,
    XpoSnackBarModule,
    ...materialModules,
    FormsModule,
    ReactiveFormsModule,
    XpoShellModule,
    XpoFooterModule,
    XpoCardModule,
    XpoLtlAuthUiModule,
    AppRoutingModule,
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    ShipmentApiService,
    BillCorrectionApiService,
    DockOperationsApiService

  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GetShipmentQuery, GetShipmentResp, ShipmentApiService } from '@xpo-ltl-2.0/sdk-shipment';
import { BehaviorSubject, EMPTY, Observable, catchError, finalize, map, of, switchMap, take } from 'rxjs';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { SnackBarService } from './snack-bar.service';
import { AbstractControl } from '@angular/forms';
import { BillStatusCd, MovementStatusCd } from '@xpo-ltl/sdk-common';
import {
  DockOperationsApiService,
  GetShipmentOnTrailerOrDockPath,
  GetShipmentOnTrailerOrDockResp
} from '@xpo-ltl/sdk-dockoperations';

@Injectable({
  providedIn: 'root'
})
export class PronumberSearchService {

  private readonly isFetchingSubject = new BehaviorSubject<boolean>(false);
  readonly isFetching$ = this.isFetchingSubject.asObservable()

  constructor(private shipmentService: ShipmentApiService,
    private snackBarService: SnackBarService,
    private dockOperationsApiService: DockOperationsApiService
  ) { }



  searchProNumber(value: string) {
    this.isFetchingSubject.next(true);

    return (((this.shipmentService
      .getShipment({ proNbr: value } as GetShipmentQuery)) as unknown) as Observable<GetShipmentResp>)
      .pipe(
        take(1),
        catchError((err) => {
          if (err?.error?.errorCode === "SHMN010-901E") {
            this.snackBarService.openSnackBar("Pro Already ACOR'd");
            return of("SHMN010-901E");
          } else {
            this.snackBarService.openSnackBar(err?.error?.message);
            return EMPTY;
          }
        }),
        switchMap((res) => {
          // Check if the billStatusCd is RATED or BILLED
          if ((<GetShipmentResp>res).shipment?.billStatusCd === BillStatusCd.RATED) {
            return of(BillStatusCd.RATED);
          } else if ((<GetShipmentResp>res).shipment?.billStatusCd === BillStatusCd.BILLED) {
            return of(BillStatusCd.BILLED);
          }

          // Now call getShipmentOnTrailerOrDock and chain the result
          return this.getShipmentOnTrailerOrDock(value).pipe(
            map((isOnDockOrTrailer: boolean) => {
              if (isOnDockOrTrailer) {
                return "OnDockOrTrailer";
              }

              // If not on dock or trailer, return the originTerminalSicCd
              return (<GetShipmentResp>res)?.shipment?.originTerminalSicCd ?? <string>res;
            })
          );
        }),
        finalize(() => {
          this.isFetchingSubject.next(false);
        })
      );
  }


  getShipmentOnTrailerOrDock(value: string): Observable<boolean> {

    this.isFetchingSubject.next(true);

    let pathParams = new GetShipmentOnTrailerOrDockPath();
    pathParams.proNbr = value;

    return this.dockOperationsApiService.getShipmentOnTrailerOrDock(pathParams).pipe(
      take(1),
      map((res: GetShipmentOnTrailerOrDockResp) => {
        if (res.shipmentOnTrailerOrDock && (res.shipmentOnTrailerOrDock?.dockName || res.shipmentOnTrailerOrDock?.equipmentNbr)) {
          return true;
        }
        return false;
      }),
      catchError((err) => {
        this.snackBarService.openSnackBar(err?.error?.message);
        return EMPTY // Return false on error
      }),
      finalize(() => {
        this.isFetchingSubject.next(false);
      })
    );
  }

}

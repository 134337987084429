import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { AcorRequest, BillCorrectionApiService, ListAcorRequestsResp } from '@xpo-ltl-2.0/sdk-billcorrection'
import { BehaviorSubject, EMPTY, catchError, take } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarService } from './snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  EXCEL_EXTENSION = '.xlsx';

  private readonly isDownLoadingSubject = new BehaviorSubject<boolean>(false);
  readonly isDownLoading$ = this.isDownLoadingSubject.asObservable()

  constructor(private billCorrection: BillCorrectionApiService,private snackBarService: SnackBarService) {
  }

  fetchDataAndGenerateExcel() {
    this.isDownLoadingSubject.next(true);

    this.billCorrection.listAcorRequests().pipe(take(1), catchError((err) => {
      this.isDownLoadingSubject.next(false);
      this.snackBarService.openSnackBar(err?.error?.message);
      return EMPTY
    }))
      .subscribe((res: ListAcorRequestsResp) => {
        
        if (res?.acorRequests?.length > 0) {
          const dataWithHeaders =this.formatTheData(res?.acorRequests);
          this.generateExcel(dataWithHeaders);
        }
        this.isDownLoadingSubject.next(false);
      });

  }

  formatTheData(acorRequests: AcorRequest[]) {
    const headers = [
      "PRO Number",
      "ACOR Owner Employee ID",
      "Origin SIC Code",
      "Request By Employee ID",
      "ACOR Reason Code",
      "ACOR Processed Indicator",
      "Email Initiate Indicator",
      "Email Escalate Indicator",
      "Email Complete Indicator",
    ];
  
    // Transform data to include headers
    const dataWithHeaders = [headers, ...acorRequests.map(item => [
      item.proNbr,
      item.acorOwnerEmployeeId,
      item.originSicCd,
      item.requestByEmployeeId,
      item.acorReasonCd,
      item.acorProcessedInd,
      item.emailInitiateInd,
      item.emailEscalateInd,
      item.emailCompleteInd,
    ])];

    return dataWithHeaders;
  }

  generateExcel(data: any) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'ACOR History');
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.EXCEL_TYPE });
    const url: string = window.URL.createObjectURL(data);
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url;
    link.download = fileName + '_export_' + new Date().getTime() + this.EXCEL_EXTENSION;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

import { Injectable } from '@angular/core';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor( private snackBar: XpoSnackBar) { }

  openSnackBar(message:string){
    this.snackBar.open({
      message:message,
      status:'error',
      matConfig: {
        duration:5000,
        verticalPosition: 'top',
      },
    });
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { MainComponent } from './main/main.component';
import { AcorFormComponent } from './acor-form/acor-form.component';

const routes: Routes = [
  {
    path: 'main',
    component: AcorFormComponent,
    canActivate: [XpoAuthenticationGuard],
  },
  { path: '', redirectTo: 'main', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PronumberSearchService } from '../services/pronumber-search.service';
import { Subscription, take, takeUntil } from 'rxjs';
import { LoggedInUserService } from '../services/logged-in-user.service';
import { BillStatusCd, MovementStatusCd, User } from '@xpo-ltl/sdk-common';
import { AcorRequestService, AcorSubmissionResponse } from '../services/acor-request.service';
import { ReportService } from '../services/report.service';
import { AcorReasonCd, } from '@xpo-ltl/sdk-common';
import { AcorRequest } from '@xpo-ltl-2.0/sdk-billcorrection';
import { SnackBarService } from '../services/snack-bar.service';



export interface ReasonCode {
  label: string;
  value: string;
}
@Component({
  selector: 'app-acor-form',
  templateUrl: './acor-form.component.html',
  styleUrls: ['./acor-form.component.scss']
})
export class AcorFormComponent implements OnInit, OnDestroy {

  private acorResponseSubscription: Subscription;

  acorForm: FormGroup;
  maxItems = 20;
  userName: string;


  reasonCodes: ReasonCode[] = [
    { label: 'Duplicate', value: AcorReasonCd.DUPLICATE },
    { label: 'No Freight Associated', value: AcorReasonCd.NO_FREIGHT_ASSOCIATED },
    { label: 'No Freight Unbilled', value: AcorReasonCd.NO_FREIGHT_UNBILLED },
    { label: 'Pro Not Picked Up', value: AcorReasonCd.PRO_NOT_PICKED_UP },
    { label: 'Wrong Shipper', value: AcorReasonCd.WRONG_SHIPPER },
  ];

  constructor(private fb: FormBuilder,
    public proSearch: PronumberSearchService,
    public loggedInUserService: LoggedInUserService,
    public acorRequestService: AcorRequestService,
    public reportService: ReportService,
    private snackBarService:SnackBarService
  ) {

    this.acorForm = this.fb.group({
      items: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.acorResponseSubscription = this.acorRequestService.acorResponse$.subscribe((response: AcorSubmissionResponse[]) => {
      this.itemsFormArray.controls.forEach((control: AbstractControl) => {
        const proNumber: string = control.get('proNumber')?.value;
        const innerItem = response.find((item) => {
          return item?.proNumber === proNumber;
        })

        if (innerItem) {
          control.get('status')?.setValue(innerItem.message);
        } else {
          control.get('status')?.setValue("Submitted Successfully");
        }

      })
    });

  }

  ngOnDestroy(): void {
    if (this.acorResponseSubscription) {
      this.acorResponseSubscription.unsubscribe();
    }
  }

  get itemsFormArray() {
    return this.acorForm.get('items') as FormArray;
  }

  addItem() {
    if (this.itemsFormArray.length < this.maxItems) {
      this.itemsFormArray.push(this.fb.group({
        proNumber: ['', [Validators.required, Validators.pattern(/^\d{9}$|^\d{11}$/)]],
        reason: ['', Validators.required],
        originSicCode: ['', Validators.required],
        status: ['']
      }));
    }
  }

  removeItem(index: number) {
    this.itemsFormArray.removeAt(index);
  }

  searchPro(item: AbstractControl<any, any>) {

    const proControl = item.get('proNumber');

    if (proControl?.invalid) {
      return;
    }

    const val: string = proControl?.value;

    this.proSearch.searchProNumber(val)
      .pipe(take(1))
      .subscribe((result) => {

        if (result === 'SHMN010-901E') {
          item.get('status')?.setValue("Already ACOR'd");
        } else if(result === BillStatusCd.RATED || result ===BillStatusCd.BILLED){
          
          item.get('status')?.reset()
          item.get('reason')?.reset();
          item.get('originSicCode')?.reset();
          proControl?.setErrors({ proIsRatedOrBilled: true });

        }else if(result === "OnDockOrTrailer"){
          item.get('status')?.reset()
          item.get('reason')?.reset();
          item.get('originSicCode')?.reset();
          proControl?.setErrors({ proOnDockOrTrailer: true });
        }else{
          item.get('status')?.setValue('');
          item.get('reason')?.reset();
          item.get('originSicCode')?.setValue(result);
          proControl.setErrors(null);
        }

      })

  }

  onSubmit() {

    const user: User = this.loggedInUserService.User;

    const acorData: AcorRequest[] = [];

    const proNumbers: string[] = this.itemsFormArray.controls.map(
      (control: AbstractControl) => control.get('proNumber')?.value
    );
    
    const duplicates = proNumbers.filter(
      (item, index) => proNumbers.indexOf(item) !== index
    );

    if(duplicates?.length !==0){
        this.snackBarService.openSnackBar('There are duplicate pro numbers.');
    }else{
      this.itemsFormArray.controls.forEach((control: AbstractControl) => {
        const proNumber: string = control.get('proNumber')?.value;
        const reason: string = control.get('reason')?.value;
        const originSicCode: string = control.get('originSicCode')?.value;
  
        acorData.push({
          proNbr: proNumber,
          acorReasonCd: this.getReasonCode(reason),
          originSicCd: originSicCode,
          requestByEmployeeId: user?.employeeId
        } as AcorRequest)
      });
      this.acorRequestService.submitAcorRequest(acorData);
    }

  }

  getReasonCode(reason: string): AcorReasonCd | undefined {
    switch (reason) {
      case AcorReasonCd.DUPLICATE:
        return AcorReasonCd.DUPLICATE;
      case AcorReasonCd.NO_FREIGHT_ASSOCIATED:
        return AcorReasonCd.NO_FREIGHT_ASSOCIATED;
      case AcorReasonCd.NO_FREIGHT_UNBILLED:
        return AcorReasonCd.NO_FREIGHT_UNBILLED;
      case AcorReasonCd.PRO_NOT_PICKED_UP:
        return AcorReasonCd.PRO_NOT_PICKED_UP;
      case AcorReasonCd.WRONG_SHIPPER:
        return AcorReasonCd.WRONG_SHIPPER;
      default:
        return undefined;
    }
  }

  downloadReport() {
    this.reportService.fetchDataAndGenerateExcel()
  }
}
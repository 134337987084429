import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { User } from '@xpo-ltl/sdk-common';
import { BehaviorSubject, Observable, map, of, share, take, tap } from 'rxjs';
import { ConfigManagerProperties } from '../enums/config-manager-properties.enum';

@Injectable({
  providedIn: 'root'
})
export class LoggedInUserService {


  private readonly userSubject= new BehaviorSubject<User>(undefined);
  readonly user$ =this.userSubject.asObservable();

  get User(){
    return this.userSubject.value;
  }


  private _runningGetLoggedInUser$: Observable<User>;

  constructor(private http: HttpClient, private configManagerService: ConfigManagerService) { }

  getLoggedInUser(loggedInUserEndpoint: string) {
   
    if (!this._runningGetLoggedInUser$) {
      this.http.get<{ data: User }>(`${this.getUrl(loggedInUserEndpoint)}`).pipe(
        take(1),
      ).subscribe((res: {data:User})=>{
          this.userSubject.next(res?.data)
      });
    }
  }



  private appendUrlParts(part1: string, part2: string): string {
    const conditionedPart1 = `${part1}${part1.endsWith('/') ? '' : '/'}`;
    const conditionedPart2 = `${part2.startsWith('/') ? part2.substring(1) : part2}`;
    return `${conditionedPart1}${conditionedPart2}`;
  }
  
  private getUrl(uri) {
    const url = this.configManagerService.getSetting<string>(ConfigManagerProperties.apiUrl);
    return this.appendUrlParts(url, uri);
  }
}

<div class="main-container">
  <div class="report-btn">
    <button mat-raised-button color="primary" [class.spinner]="this.reportService.isDownLoading$ | async"
      [disabled]=" (this.reportService.isDownLoading$ | async) || (acorRequestService.submitInProgres$ |async)"
      (click)="downloadReport()">Download Report</button>
  </div>

  <div class="acor">
    <h1> ACOR Request</h1>
    <p>Requested By : {{ (loggedInUserService.user$ | async)?.displayName }}</p>
    <div>
      <mat-progress-spinner *ngIf="(acorRequestService.submitInProgres$ |async) || (proSearch.isFetching$|async) "
        mode="indeterminate"></mat-progress-spinner>
    </div>
    <form class="form" [formGroup]="acorForm">
      <div formArrayName="items" class="form-items">
        <div *ngFor="let item of itemsFormArray.controls; let i = index" [formGroupName]="i" class="form_data">
          <div class="pro-input-container">
            <div class="pro-input">
              <mat-label>PRO:</mat-label>
              <input class="input-field" type="text" formControlName="proNumber" (input)="searchPro(item)"
                placeholder="Enter a PRO" />
            </div>
            <div *ngIf="item.get('proNumber').invalid && (item.get('proNumber').dirty || item.get('proNumber').touched)"
              class="error-message">
              <mat-error *ngIf="item.get('proNumber').errors['required']">Required.</mat-error>
              <mat-error *ngIf="item.get('proNumber').errors['pattern']"> Must be 9 or 11 digits.</mat-error>
              <mat-error *ngIf="item.get('proNumber')?.hasError('proIsRatedOrBilled')">PRO is billed or rated</mat-error>
              <mat-error *ngIf="item.get('proNumber')?.hasError('proOnDockOrTrailer')">PRO is on a dock or trailer</mat-error>
            </div>
          </div>
          <div class="origin_sic">
            <mat-label>Originating SIC : {{ item.get('originSicCode')?.value }} </mat-label>
          </div>
          <div class="reason-code">
            <mat-form-field appearance="fill" class="reason-select">
              <mat-select formControlName="reason" placeholder="Select Reason Code">
                <mat-option *ngFor="let reasonCode of reasonCodes" [value]="reasonCode?.value"
                  style="background: white">
                  {{ reasonCode?.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="item.get('reason').invalid && (item.get('reason').dirty || item.get('reason').touched)"
              class="error-message">
              <mat-error *ngIf="item.get('reason').errors['required']">Reason Code is required.</mat-error>
            </div>
          </div>
          <div class="delete-pro">
            <button type="button" (click)="removeItem(i)" *ngIf="itemsFormArray.length >= 1" matTooltip="Remove Row"
              matTooltipPosition="above" class="delete-btn">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <div class="status">
            <mat-label>Status : {{ item.get('status')?.value }} </mat-label>
          </div>
        </div>
      </div>
    </form>
    <div class="action-btn">
      <button mat-raised-button color="primary" (click)="addItem()"
        [disabled]="acorRequestService.submitInProgres$ |async">Add PRO</button>
      <button *ngIf="itemsFormArray.length > 0" mat-raised-button color="primary" (click)="onSubmit()"
        [disabled]="acorForm.invalid || (acorRequestService.submitInProgres$ |async)"
        [ngClass]="{
          'button-disabled': acorForm.invalid || (acorRequestService.submitInProgres$ | async)
        }"
        >Submit</button>
    </div>
  </div>
</div>